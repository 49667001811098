@import "@ng-select/ng-select/themes/default.theme.css";
body,html{
    font-family: 'Lato', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    color:#777777;
    background-color:#F3F7FA !important;
    height: 100%;
}
.f-12{
    font-size:12px;
}
.f-16{
    font-size:16px;
}
hr {
    border-color: #F3F7FA !important;
    }
.btn {
    cursor: pointer;
    font-size:14px;
}

.btn:focus {
    box-shadow: none !important;
    outline:0;
}
.btn-success{
   color:#fff !important; 
   background-color:#36B44C;
}
.btn-dark {
    background: #1A2B36!important;
}
.icon-btn{
    border:none;
    background:transparent;
    font-size:20px !important;
}
.main-cont-top{
    margin-top:90px;
}
.main-cont-top > .card {
    min-height: 95%;
}
.detail-container{
    background-color:#fff;
}
/*left side menu css*/
.sidebar-div{
    background-color:#fff;
    box-shadow:1px 0px 20px rgba(0,0,0,28%);
}
/**/
.dropbar label{
    font-size:18px;
    /* padding-top:5px; */
}
.detail-container{
    background-color:#fff;
}
.top-border-green{
    border-radius: 0px;
    border-top: solid 3px #2cac57 !important;
}
.top-border-pink{
    border-radius: 0px;
    border-top: solid 3px #e32484 !important;
}
.top-border-darkblue{
    border-top: solid 3px #584891 !important;
}
.top-border-red{
    border-top: solid 3px #fd7090 !important;
}
.top-border-orange{
    border-top: solid 3px #faac57 !important;
}
.setHeightForTop5List{
    min-height:410px;
    /* height:750px; */
}
.card{
    margin-bottom:20px;
}
.accordion>.card {
    margin-bottom: 0 !important;
}

.img-div{
    width:inherit;
}
.card-icons{
    width:40px;
   height:40px;
}
.text-box{
    padding:0 ;
}
.text-muted{
    color:#777777;
}
 .text-label{
    color: #777777 !important;
    font-size: 14px;
    margin-bottom: 0;
}
.text-box h3{
    color: #777777 !important;
    font-size: 20px;
    white-space: nowrap; 
    width: 115px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.outgoing-db .text-box h3{
    width: 100px;
}
.msg-det-cards .card{
 margin-right:20px;
 border-radius:5px;
}
.msg-det-cards .card:last-child{
    margin-right:0;
}
.dropbar{
    border-radius:5px !important; 
}
/*loader gif css*/
.msg-det-cards .loader-gif{
   width:50px;
}
.inprogressIcons{
    width:16px;
}
/* width */
::-webkit-scrollbar {
    height: 5px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius:20px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c9c9c9; 
    border-radius:25px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #777; 
  }
/* .dropbar .card-body{
    padding:15px 10px 0;
} */
.msg-det-cards .card-body{
    padding:10px 14px 0;
}

::placeholder {
    color: #777 !important;
    opacity: 1; 
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif, FontAwesome;
    float:right;
    padding-top:2px;
  }
  .searchIconvisible {
    font-family:  Helvetica, Arial, Verdana, Tahoma, sans-serif, FontAwesome !important;
}
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #777;
  }
  
/*nav tabs*/
.nav-tabs{
    border-bottom:0 !important;
}
.card-title{
    color: #231942 !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    font-size: 18px !important;
    padding-top:8px;
    margin-bottom:0!important;
}
.message-transmission li{
    display:inline;
    padding:8px 4px;
}

.tab-content{
    clear:both;
    margin:10px auto;
}
.fa-ellipsis-v{
    color:rgb(174, 180, 185);
}
.icon-btn::after{
   border:0px solid transparent !important;
}

/*calendar picker css*/

/* .md-drppicker.drops-down-auto.ltr.shown.show-ranges{

} */
.md-drppicker.drops-down-auto.ltr.shown.double.show-ranges{
    left:45px !important;
}
 .md-drppicker.ltr .ranges{
    float:right !important;
}
.md-drppicker.show-ranges{
    width:max-content !important;
    top:50px !important;
}
.md-drppicker .ranges ul li button.active {
    background-color: #1A2B36 !important;
    border: 1px solid #1A2B36 !important;
    color: #fff !important;
}
.md-drppicker .ranges ul li{
    display:block !important;
    width:180px;
}
.md-drppicker .ranges ul li button {
    color: #000 !important;
    background-color: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important;
    border-radius: 4px;
    margin:5px 0;
}
.md-drppicker .buttons .buttons_input{
    clear:both;
}
.md-drppicker .btn{
    background: #55ce63 !important;
    border: 1px solid #55ce63 !important;
}
/*tabview manager css*/
.tab-view-manager li a{
    background: #ddd;
    color:#36353d !important;
    border-radius:0 !important;
}

.tab-view-manager .nav-item.show .nav-link, 
.tab-view-manager .nav-link.active{
    color: #fff !important;
    background-color: #1A2B36 !important;
    border:1px solid #1A2B36 !important;
   
}

/*table background color css*/
.table{
    font-size:14px;
}
table td a{
    color:inherit !important;
}
.court-dashboard-table th{
    background-color: #231942;
    color: #fff;
    border:solid 1px #777;

}
.table-all-interface.interface-table td, 
.table-all-interface.interface-table th{
	padding:10px 5px;
}
.first-table-color{background: #DEDEDE   !important;color:#777 !important;} 
.second-table-color {background: #ECECEC  !important; color:#777 !important;}
.third-table-color{background:#F5F5F5  !important; color:#777 !important;}
.fourth-table-color{background:white !important; color:#777 !important;}
/**/
.blue-text{
    color:blue;
}
.wd-filters{
    list-style-type: none;
}
.wd-filters li {
    display: inline-block;
    padding: 0 5px;
}
.caption-download {
    padding: 8px 2px !important;
    background: none!important;
    color: #777777 !important;
    border: none;
    font-size: 16px;
}
.w-80{
    max-width:80% !important;
    flex:0 0 80% !important;
}
.w-20{
    flex: 0 0 20%  !important;
    max-width: 20%  !important;
}
/*conversation page css*/
.card-label{
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    padding-top:5px;
}
.convo-msg-list{
    padding:10px 15px;
    color:#777;
}
.convo-msg-list li{
background:#fff;
}
.f-10{
    font-size:10px;
}
.three-dots{
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis; 
}
.grey-bg {
    background-color: #F3F7FA !important;
    margin-bottom: 10px;
    padding: 10px 10px;
}
.page-heading{
    color: #999999 ;
    font-weight: 500 ;
    font-size: 18px;
    padding-top:6px;
}
/* Tree Structure CSS */

.t-e {
	display: inline-block;
  	height: 28px;
  	width: 28px;
}

.t-b {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/branch.svg');
}

.t-cb {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/c-branch.svg');
}

.t-cn {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/c-node.svg');
}

.t-cn-green {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/c-node-green.svg');
}

.t-cpb {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/cp-branch.svg');
}

.t-cpb1 {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/cp-branch1.svg');
}

.t-cpn {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/cp-node.svg');
}

.t-line {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/line.svg');
}

.t-cpn-red {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/cp-node-red.svg');
}

.t-cpn-yellow {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/cp-node-yellow.svg');
}

.t-root {
	display: inline-block;
  	height: 28px;
  	width: 28px;
	background-image: url('assets/icons/tree_stru_icon/root.svg');
}


.t-cptn
{
	line-height: 28px;
    color: #333;
}

.tree-row {
	display: flex;
}


.attempted-table, .dropped-table{
    color: #333;
}

.attempted-table-header, .dropped-table-header{
    border: 2px solid #eaeaea;
    border-right: none;
    border-left: none;
    /* margin: 30px 0px; */
    height: 50px;
}

table {
    border-collapse: collapse !important;
}
.attempted-table th, .attempted-table td, 
.dropped-table th, .dropped-table td {
    padding: 0 !important;
    border-spacing: 0px !important;
    font-size:12px;
}
.attempted-table th:first-child,
.dropped-table th:first-child {
	border-top:3px solid #64b0b5;

}

.attempted-table th, .attempted-table td {
    padding: 0 !important;
    border-spacing: 0px !important;
    font-family: lato , sans-serif;
}
.attempted-card-title,
.dropped-card-title{
    font-size: 16px;
    font-weight: 600;
    color: #777777;
}
.table-download-btn{
	width:55px;
}
.delivery-chart-details{
    color:#999;
}
.comparison-table {
    text-align:center;
    color:#777 !important;
}
.comparison-table th{
    background: #231942;
    font-size: 14px;
    font-weight: 600;
    color: #fff!important;
    padding:10px 40px;
}
.comparison-table th.active{
    background: #B5F0EE!important;
    color: #666!important
}
.comparison-table th,
.comparison-table td{
    border:1px solid #dee2e6 !important;
}
/* outgoing tab css */
.outgoing-tab .nav-link:focus,
 .outgoing-tab .nav-link:hover{
    background: none !important;
    border-color: transparent !important;
    color: #231942;
}
.outgoing-tab .nav-item.show .nav-link,
 .outgoing-tab .nav-link.active{   
    color: #231942;  
}
.outgoing-tab .nav-link{
    background: none !important;
    border-color: transparent !important;
    font-size:18px;
    color:#999999;
}
@media screen and (max-width: 1450px) and (min-width: 1200px){
    .dropbar label {
        font-size: 15px !important;
    }
    .date-select-div{
        width: 128px !important;
    }
    .ng-custom .select2-selection {
        width: 130px !important;
    }
}

tbody tr td.ng2-smart-action-multiple-select input {
    width: 13px;
}
thead tr:first-child th {
    border-bottom: 0;
}
thead tr:last-child th {
    border-top: 0;
}
.accordion .btn.collapsed .fa-stack {
    transform: rotate(90deg);;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-left {
    top: 12px;
    left: 12px;
  }
  .toast-top-right {
    top: 12px;
    right: 12px;
  }
  .toast-bottom-right {
    right: 12px;
    bottom: 12px;
  }
  .toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }
  
  /* toast styles */
  .toast-title {
    font-weight: bold;
  }
  .toast-message {
    word-wrap: break-word;
  }
  .toast-message a,
  .toast-message label {
    color: #FFFFFF;
  }
  .toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
  }
  .toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
  }
  .toast-close-button:hover,
  .toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
  }
  /*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
  button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
  .toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
  }
  .toast-container * {
    box-sizing: border-box;
  }
  .toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
  }
  .toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
  .toast-info {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
  .toast-error {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
  .toast-success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
  .toast-warning {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
  }
  .toast-container.toast-top-center .ngx-toastr,
  .toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .toast-container.toast-top-full-width .ngx-toastr,
  .toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
  .ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
  }
  .toast-success {
    background-color: #51A351;
  }
  .toast-error {
    background-color: #BD362F;
  }
  .toast-info {
    background-color: #2F96B4;
  }
  .toast-warning {
    background-color: #F89406;
  }
  .toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
  }
  /* Responsive Design */
  @media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }
    .toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
  @media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
      padding: 8px 8px 8px 50px;
      width: 18em;
    }
    .toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
  @media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
      padding: 15px 15px 15px 50px;
      width: 25em;
    }
  }

/* control-table -start */
.control-table-accordian .btn-link {
  color: #0e0e0e;
  font-weight: bold;
}
.control-table-accordian .btn-link.collapsed .fa-angle-up {
  transform: rotate(180deg) !important;
}
.control-table-accordian .btn-link i {
  font-size: 1.5rem;
  font-weight: 900;
}
.control-table-accordian .btn-link:hover,
.control-table-accordian .btn-link:focus {
  color: black;
  text-decoration: none;
}

/* table style */

.table th {
  border-top: 0px !important;
}
.table .ng2-smart-row:last-child td {
  border-bottom: 1px solid #dee2e6 !important;
}

.table .completer-input {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline-offset: 0px;
}
.table .ng2-smart-sort-link {
  font-size: 14px;
  color:  #212529;
}
.ng2-smart-page-item .page-link{
  min-height: 35px;
  height: 35px;  
}
.ng2-smart-page-item.active .page-link{
   background: none;
   color: #007bff;
 }
 /*added on 5/12/2022*/
 /* Login */

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loginForm {
  flex-basis: 40%;
}

.center {
  text-align: center;
}

.error {
  color: red;
}

.login-btn-container {
  display: flex;
  justify-content: flex-end;
}

.login-btn-container-error {
  display: flex;
  justify-content: space-between;
}

.login-btn {
  flex-basis: 20%;
}
select {
  appearance: auto; /* Ensures default dropdown arrow is shown */
  -webkit-appearance: auto; /* For Safari */
  -moz-appearance: auto; /* For Firefox */
}
